import { useState, useEffect, useMemo, useCallback } from "react";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import { Container, Row, Col, Button, Alert, Modal, Form, Accordion } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAppSelector, useAppDispatch } from "../../app/store";
import { getQuery } from "../../app/util";
import { fieldViewData, ProfileFieldStatus, SearchCondition } from "./profileValues";
import { isPermitted } from "../permission/permissionValues";
import { selectUserState } from "../login/userSlice";
import { getSectors, getRegularColumns, selectClientState, selectMasterActiveSectors } from "../client/clientSlice";
import {
  getMasterListView,
  downloadMasterListView,
  selectMasterDataState,
  createEmptyProfile,
  stageProfileSubFields,
  getMasterRecordAsProfileField,
  deleteMasterRecord,
  commitMasterData,
} from "../masterData/masterDataSlice";
import { MASTER_TABLE_PREFIX } from "../masterData/masterDataValues";
import FieldListSelector from "./FieldListSelector";
import SnapshotTimeSelector, { onChangeOption } from "./SnapshotTimeSelector";
import Icon from "../../component/Icon";
import ModalDialog from "../../component/ModalDialog";
import Table from "../../component/Table";
import dayjs from "dayjs";
import "react-calendar/dist/Calendar.css";
import { RegularColumn } from "../client/clientValues";
import ProfileField from "./ProfileField";
import { FieldValidator, ProfileSubField, diffCollector, generateValidator } from "./profileFieldValues";

function MasterDataList() {
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const query = useMemo(() => {
    return getQuery(search);
  }, [search]);
  const {
    previewData,
    masterIdLabels,
    profileField,
    previewDataTotalCount,
    previewDataHasMore,
    fetchedPreviewDataPage,
  } = useAppSelector(selectMasterDataState);
  const { sectorRegularColumns } = useAppSelector(selectClientState);
  const activeMasterSectors = useAppSelector(selectMasterActiveSectors);
  const { policies } = useAppSelector(selectUserState);
  const [state, $state] = useState({
    activeFieldType: (query.type as "latest" | "history") || "latest",
    fieldViewSectors: [] as fieldViewData[],
    selectedPointDate: dayjs(),
    targetIndex: -1,
    timeoutId: null as number | null,
    inputKeyword: "", // 入力キーワード
    searchKeyword: "", // 検索用キーワード（入力し終わって500ms経過）
  });
  const [activeModal, $activeModal] = useState("");
  const [fieldStatus, $fieldStatus] = useState({} as ProfileFieldStatus);

  const [selectedSectorId, selectedSectorLabel, selectedSectorColumns] = useMemo(() => {
    const selectedSector = state.fieldViewSectors.find((_) => _.checked);
    if (!selectedSector) return ["", "", []];
    return [
      selectedSector.id,
      selectedSector.label,
      sectorRegularColumns[`${MASTER_TABLE_PREFIX}${selectedSector.id}`],
    ];
  }, [state.fieldViewSectors]);

  // 横スクロール時の固定カラム
  const stickyColumns = useMemo(() => {
    if (selectedSectorId) {
      return sectorRegularColumns[`${MASTER_TABLE_PREFIX}${selectedSectorId}`]
        ?.filter((_) =>
          ["valid_from", "valid_to", `${selectedSectorId}_code`, `${selectedSectorId}_name`].includes(_.id)
        )
        ?.map((_) => _.id);
    } else {
      return [];
    }
  }, [selectedSectorId]);

  const selectField = async (id: string) => {
    const sectorId = `${MASTER_TABLE_PREFIX}${id}`;
    const isSector = activeMasterSectors.some((s) => s.sector_id === sectorId);

    const newSubFields = await (async () => {
      if (isSector && !sectorRegularColumns[sectorId]) {
        const { payload } = await dispatch(getRegularColumns({ sectorId }));
        const { regularColumns } = payload as { [key: string]: any };
        return regularColumns.map((col: RegularColumn) => {
          const { id, label } = col;
          return { id, label };
        });
      }
    })();
    const willSubFieldChecked = (
      subFieldId: string,
      subFieldCheckedCurrent: boolean,
      fieldCheckedNext: boolean,
      fieldCheckedCurrent: boolean
    ) => {
      if (fieldCheckedCurrent && !fieldCheckedNext) {
        return false;
      } else if (!fieldCheckedCurrent && fieldCheckedNext) {
        return true;
      } else {
        return subFieldId === id ? !subFieldCheckedCurrent : subFieldCheckedCurrent;
      }
    };
    const next = state.fieldViewSectors.map((field) => {
      const fieldCheckedNext = field.id === id ? !field.checked : field.checked;
      const subFields = field.id === id ? newSubFields || field.subFields : field.subFields;
      return {
        ...field,
        // まずは1つしか選べない
        checked: isSector ? (field.id === id ? !field.checked : false) : fieldCheckedNext,
        subFields: subFields.map((sub: any) => ({
          ...sub,
          checked: willSubFieldChecked(sub.id, sub.checked, fieldCheckedNext, field.checked),
        })),
      };
    });
    $state({ ...state, fieldViewSectors: next });
    const checked = next.find(({ checked }) => checked);
  };

  const selectAllSubFields = (fieldId: string, isSelectAll: boolean) => {
    const next = state.fieldViewSectors.map((field) => {
      return {
        ...field,
        checked: field.checked,
        subFields: field.subFields.map((sub: any) => ({
          ...sub,
          checked: field.id === fieldId ? isSelectAll : sub.checked,
        })),
      };
    });
    $state({ ...state, fieldViewSectors: next });
  };

  const [fieldIds, exceptionSubFieldIds] = useMemo(() => {
    const fieldIds = [] as string[];
    const exceptionSubFieldIds = [] as string[];
    state.fieldViewSectors.forEach((field) => {
      if (!field.checked) return;
      fieldIds.push(field.id);
      field.subFields.forEach((sub) => {
        if (!sub.checked) exceptionSubFieldIds.push(sub.id);
      });
    });
    return [fieldIds, exceptionSubFieldIds];
  }, [state.fieldViewSectors]);

  const updatePreview = async ({
    page = 1,
    options = {},
  }: {
    page?: number;
    options?: { activeFieldType?: "latest" | "history" };
  }) => {
    const activeFieldType = options?.activeFieldType ?? state.activeFieldType;
    const conditions = {} as SearchCondition;
    if (state.searchKeyword) {
      conditions["keyword"] = state.searchKeyword;
    }
    if (fieldIds.length > 0) {
      await dispatch(
        getMasterListView({
          fieldIds,
          exceptionSubFieldIds,
          baseDate: activeFieldType === "latest" ? state.selectedPointDate.format("YYYY-MM-DD") : "",
          sectorRegularColumns,
          conditions,
          page: page,
        })
      );
    }
    const p =
      activeFieldType === "latest"
        ? `type=${activeFieldType}&base_date=${state.selectedPointDate.format("YYYY-MM-DD")}`
        : `type=${activeFieldType}`;
    window.history.replaceState(
      {},
      "",
      `/_/property/codes/?${fieldIds.length > 0 ? `field=${fieldIds[0]}&${p}` : `${p}`}`
    );
  };

  const downloadMasterList = async () => {
    await dispatch(
      downloadMasterListView({
        fieldIds,
        exceptionSubFieldIds,
        baseDate: state.activeFieldType === "latest" ? state.selectedPointDate.format("YYYY-MM-DD") : "",
        sectorRegularColumns,
      })
    );
  };

  const closeModal = () => {
    $activeModal("");
    $fieldStatus({});
    $state({ ...state, targetIndex: -1 });
  };

  const validateProfileField: FieldValidator = useCallback(
    (subFields: ProfileSubField[]) => {
      const subFieldsData = selectedSectorColumns.reduce(
        (prev: { [fieldName: string]: any }, { input_type, id }: RegularColumn) => {
          return { ...prev, [id]: { type: input_type } };
        },
        {}
      );
      return generateValidator(subFieldsData)(subFields);
    },
    [selectedSectorColumns]
  );

  const setFieldStatus = (validated: boolean, subFields: ProfileSubField[], subFieldIndex?: number) => {
    const f = profileField.fieldName;
    const next = { ...fieldStatus };
    next[f] = next[f] || { validated: true, errorMessage: "" };
    next[f].validated = validated;
    next[f].errorMessage = validated ? "" : "入力内容を確認してください";
    subFields.forEach((sf, i) => {
      if (subFieldIndex && i !== subFieldIndex) return;
      const path = `${f}/${sf.id}`;
      next[path] = next[path] || { validated: true, errorMessage: "" };
      next[path].validated = !sf.errorMessage;
      next[path].errorMessage = sf.errorMessage ?? "";
    });
    $fieldStatus(next);
  };

  useEffect(() => {
    if (activeMasterSectors.length === 0) {
      dispatch(getSectors());
    } else {
      getDefaultField();
    }
  }, [activeMasterSectors.length]);

  const getDefaultField = async () => {
    const masterDataSectors = activeMasterSectors.filter((s) => s.sector_id.startsWith(MASTER_TABLE_PREFIX));
    const fieldViewSectors = await Promise.all(
      masterDataSectors.map((sector) => {
        return new Promise<fieldViewData>(async (resolve, reject) => {
          try {
            const sectorId = sector.sector_id;
            const checked = sectorId.replace(MASTER_TABLE_PREFIX, "") === query.field;

            const regularColumns = await (async () => {
              const _columns = sectorRegularColumns[sectorId];
              if (!checked) return _columns;
              if (_columns) return _columns;
              const { payload } = await dispatch(getRegularColumns({ sectorId }));
              const { regularColumns } = payload as { [key: string]: any };
              return regularColumns;
            })();

            const subFields: { id: string; label: string; checked: boolean }[] =
              regularColumns?.map((col: RegularColumn) => {
                const { id, label } = col;
                return { id, label, checked: checked };
              }) ?? [];
            resolve({
              id: sectorId.replace(MASTER_TABLE_PREFIX, ""),
              label: sector.logical_name,
              checked: checked,
              subFields,
            });
          } catch (e) {
            reject(e);
          }
        });
      })
    );
    $state({ ...state, fieldViewSectors });
  };

  useEffect(() => {
    if (state.fieldViewSectors.length > 0) {
      updatePreview({});
    }
  }, [state.fieldViewSectors, state.selectedPointDate, state.searchKeyword]);

  const isEditPermitted = useMemo(() => {
    const sector = state.fieldViewSectors.find((_) => _.checked)?.id;
    if (!sector) return false;
    return isPermitted(policies, `user_data_manager/${sector}`, "PUT");
  }, [policies, state.fieldViewSectors]);

  useEffect(() => {
    // 編集権限がない、かつ基準日が未来の場合は基準日を本日へ変更する
    if (!isEditPermitted && state.selectedPointDate.isAfter(dayjs())) $state({ ...state, selectedPointDate: dayjs() });
  }, [isEditPermitted]);

  return (
    <Container>
      <Row className="mt-2">
        <Col md="2">
          <div className="--bold pt-md-3">表示テーブル</div>
        </Col>
        <Col md="10">
          <FieldListSelector
            fields={state.fieldViewSectors}
            onFieldSelected={selectField}
            onSelectAllSubFields={selectAllSubFields}
            useSelectAllSubFields={true}
          />
        </Col>
      </Row>
      {state.activeFieldType === "latest" ? (
        <Row className="mt-2">
          <Col md="2">
            <div className="--bold pt-md-3">基準日</div>
          </Col>
          <Col md="10">
            <SnapshotTimeSelector
              selectedPointDate={state.selectedPointDate}
              onChange={({ selectedPointDate }: onChangeOption) => {
                if (!selectedPointDate) return;
                $state({
                  ...state,
                  selectedPointDate,
                });
              }}
              useFarthestDaySelector={isEditPermitted}
              maxDate={isEditPermitted ? undefined : dayjs().toDate()}
            />
          </Col>
        </Row>
      ) : (
        ""
      )}
      <Row className="mt-2">
        <Col md="2">
          <div className="--bold pt-md-3">絞込条件</div>
        </Col>
        <Col md="10">
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>{state.searchKeyword ? "（設定中）" : "（未設定）"}</Accordion.Header>
              <Accordion.Body>
                <Form.Control
                  type="text"
                  id="search"
                  value={state.inputKeyword}
                  placeholder="キーワード（コード・名称）で絞り込む"
                  onChange={(e) => {
                    const keyword = e.target.value;
                    // 打ち終わって500ms後に検索のリクエストをする
                    if (state.timeoutId) {
                      window.clearTimeout(state.timeoutId);
                    }
                    const timeoutId = window.setTimeout(() => {
                      $state({ ...state, timeoutId: null, searchKeyword: keyword, inputKeyword: keyword });
                    }, 500);
                    $state({ ...state, timeoutId, inputKeyword: keyword });
                  }}
                />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <Button
            className="mr-2"
            variant="outline-secondary"
            disabled={!selectedSectorId || previewData.length === 0}
            onClick={downloadMasterList}
          >
            ダウンロード
          </Button>
          {selectedSectorId && isPermitted(policies, `master_data_manager/${selectedSectorId}`, "POST") && (
            <Button
              className="mx-2"
              disabled={!selectedSectorId}
              variant="outline-primary"
              onClick={() => {
                $activeModal("before_create");
              }}
            >
              履歴追加
            </Button>
          )}
          <Button
            className="mr-2 float-end"
            variant="outline-secondary"
            onClick={() => {
              const newFieldType = state.activeFieldType === "latest" ? "history" : "latest";
              $state({ ...state, activeFieldType: newFieldType });
              updatePreview({ options: { activeFieldType: newFieldType } });
            }}
          >
            {state.activeFieldType === "latest" ? "履歴表示に切り替える" : "基準日表示に戻す"}
          </Button>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md="12">
          {selectedSectorId && previewData.length > 0 ? (
            isPermitted(policies, `master_data_manager/${selectedSectorId}`, "PUT") ||
            isPermitted(policies, `master_data_manager/${selectedSectorId}`, "DELETE") ? (
              <Table
                col={previewData[0]?.map((col, i) => {
                  return {
                    name: col,
                    colName: selectedSectorColumns?.length > i ? selectedSectorColumns[i].id : "",
                    className: classNames({ "--sticky": i < stickyColumns.length }),
                    left: 160 + (previewData[0].length > 6 ? 0 : 6 - previewData[0].length) * 50,
                    width: 160 + (previewData[0].length > 6 ? 0 : 6 - previewData[0].length) * 50,
                  };
                })}
                row={previewData
                  .filter((_, i) => i > 0)
                  .map((row, i) => ({
                    data: row,
                    appendAfter: {
                      valid_from: (
                        <span className="ms-1">
                          {masterIdLabels[i].id === 0 && <Icon width={16} height={16} type="info-circle-fill" />}
                        </span>
                      ),
                    },
                    action: {
                      handler: async () => {
                        if (masterIdLabels[i].id === 0) {
                          $activeModal("cannot_edit");
                        } else {
                          await dispatch(
                            getMasterRecordAsProfileField({
                              sectorId: selectedSectorId,
                              id: masterIdLabels[i].id,
                              columns: selectedSectorColumns,
                              forUpdate: true,
                            })
                          );
                          $activeModal("input");
                        }
                      },
                    },
                  }))}
              />
            ) : (
              <Table
                col={previewData[0]?.map((col, i) => ({
                  name: col,
                }))}
                row={previewData
                  .filter((_, i) => i > 0)
                  .map((row, i) => ({
                    data: row,
                  }))}
              />
            )
          ) : (
            <Alert variant={"info"}>該当するレコードはありません。</Alert>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          {previewData.length > 1 ? (
            <Button
              variant="outline-secondary"
              className="mt-2 float-end"
              disabled={!previewDataHasMore}
              onClick={() => {
                updatePreview({ page: fetchedPreviewDataPage + 1 });
              }}
            >
              さらに表示（全 {previewDataTotalCount} 件中 {previewData.length - 1} 件表示中）
            </Button>
          ) : null}
        </Col>
      </Row>
      <ModalDialog
        show={activeModal === "cannot_edit"}
        onConfirm={() => $activeModal("")}
        message="親部署情報の履歴追加により表示されているレコードのため、編集することができません。編集したい場合は、履歴表示に切り替えて、同一部署コードのアイコンが表示されていないレコードを選択してください。"
        type="alert"
      />
      <Modal
        show={activeModal === "input"}
        onHide={closeModal}
        size="xl"
        className="mt-4"
        centered
        scrollable
        backdrop="static"
      >
        <Modal.Body>
          <Row>
            <Col md={12}>
              <ProfileField
                table={profileField.table}
                fieldName={profileField.fieldName}
                labelMap={(() => {
                  let labelMap = profileField.labelMap["ja"];
                  labelMap = { ...labelMap, [profileField.fieldName]: selectedSectorLabel };
                  return { ja: labelMap };
                })()}
                subFields={profileField.subFields}
                isEditing={true}
                dataType={"master"}
                fieldStatus={fieldStatus}
                onChange={(next, subFieldIndex) => {
                  const { validated, subFields } = validateProfileField(next);
                  setFieldStatus(validated, subFields, subFieldIndex);
                  dispatch(stageProfileSubFields({ subFields }));
                }}
                onCommit={async (subFields, validDateDiff) => {
                  const diff = diffCollector(subFields, validDateDiff);
                  if (diff.length) await dispatch(commitMasterData({ sectorId: selectedSectorId, diff }));
                  closeModal();
                  updatePreview({});
                }}
                onEditOrCancel={(next, isEditing) => {
                  if (!isEditing) closeModal();
                  else {
                    const { validated, subFields } = validateProfileField(next);
                    setFieldStatus(validated, subFields);
                    dispatch(stageProfileSubFields({ subFields }));
                  }
                }}
                onDelete={async (_, id) => {
                  await dispatch(deleteMasterRecord({ sectorId: selectedSectorId, id }));
                  closeModal();
                  updatePreview({});
                }}
                onChangeFieldStatus={(_fieldStatus) => {
                  $fieldStatus({ ...fieldStatus, ..._fieldStatus });
                }}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <Modal show={activeModal === "before_create"} onHide={closeModal} size="lg" centered>
        <Modal.Body>
          履歴追加する対象を選択してください。
          {masterIdLabels.length && (
            <Form.Select
              value={state.targetIndex}
              onChange={(v) => {
                const value = +v.target.value;
                $state({ ...state, targetIndex: value });
              }}
              className="mt-2"
            >
              <option value={-1}>---</option>
              {masterIdLabels.map(({ label }, i) => {
                return (
                  <option key={`option${i}`} value={i}>
                    {label}
                  </option>
                );
              })}
            </Form.Select>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeModal} variant="outline-secondary">
            キャンセル
          </Button>
          <Button
            onClick={() => {
              dispatch(createEmptyProfile({ sectorId: selectedSectorId, columns: selectedSectorColumns }));
              const fieldStatus = {
                [selectedSectorId]: { validated: false, errorMessage: "入力してください" },
              } as ProfileFieldStatus;
              $fieldStatus(fieldStatus);
              $activeModal("input");
            }}
            variant="primary"
          >
            新しい{selectedSectorLabel}を追加する
          </Button>
          <Button
            onClick={async () => {
              const fieldStatus = {
                [selectedSectorId]: { validated: false, errorMessage: "入力してください" },
              } as ProfileFieldStatus;
              $fieldStatus(fieldStatus);

              const params = (() => {
                // idが0以外の場合はidを指定して取得
                const master = masterIdLabels[state.targetIndex];
                const id = master.id;
                if (id !== 0) return { id };
                // idが0の場合はユニークキーを使って取得
                return {
                  uniqueKeys: {
                    base_date: state.selectedPointDate.format("YYYY-MM-DD"),
                    [`${selectedSectorId}_code`]: master.codeValue,
                  },
                };
              })();

              await dispatch(
                getMasterRecordAsProfileField({
                  sectorId: selectedSectorId,
                  columns: selectedSectorColumns,
                  ...params,
                })
              );
              $activeModal("input");
            }}
            variant="primary"
            disabled={state.targetIndex === -1}
          >
            選択した{selectedSectorLabel}に履歴追加
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default MasterDataList;
