import jwt_decode from "jwt-decode";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { API_ORIGIN_ASSESSMENT, call } from "../../app/api";
import { decodedToken } from "./idTokenSlice";

const SLICE_NAME = "user";

export type PolicyMap = { [apiName: string]: string[] };

export type CompanyChoice = {
  company_code: string;
  company_id: number;
  company_name: string;
  services: { [role: string]: number[] };
  vendor_company_code: string;
  vendor_company_id: number;
  vendor_company_name: string;
};

export type CurrentCompany = {
  code: string;
  company_name: string;
  id: number;
  services: {
    admin?: number[];
    user?: number[];
  };
  timezone: string;
  vendor_company_id: number;
};

export interface User {
  account_name: string;
  can_change_role: boolean;
  current_company: CurrentCompany;
  id: number;
  language: string;
  login_code: string;
  mail_address: string;
  main_company: string;
  role: string;
  services: number[];
  profile_roles: string[];
  companyChoices: CompanyChoice[];
}

export interface UserState {
  refreshToken: string;
  user: User;
  roles: string[];
  policies: PolicyMap;
  exp: number;
  processing: boolean;
  errorMessage: string;
  initialized: boolean; // 自動ログインなど、ajaxを含む初期処理が完了しているか
  newPasswordErrorMessage: string;
  newPasswordErrorHint: number;
}

/*
  state の初期状態
*/
const initialState: UserState = {
  refreshToken: "",
  user: {
    id: 0,
    main_company: "",
    login_code: "",
    account_name: "",
    mail_address: "",
    language: "",
    role: "",
    can_change_role: false,
    services: [],
    profile_roles: [],
    current_company: {
      id: 0,
      code: "",
      company_name: "",
      timezone: "Asia/Tokyo",
      vendor_company_id: 0,
      services: {},
    },
    companyChoices: [],
  },
  exp: 0,
  roles: [],
  policies: {},
  processing: false,
  errorMessage: "",
  initialized: false,
  newPasswordErrorMessage: "",
  newPasswordErrorHint: 0,
};

export const getUserByIdToken = createAsyncThunk(SLICE_NAME + "/getUserByIdToken", async (idToken: string) => {
  const { current_user, exp }: decodedToken = jwt_decode(idToken);
  return {
    user: current_user,
    exp,
    roles: current_user.profile_roles,
  };
});

export const getMyPolicy = createAsyncThunk(SLICE_NAME + "/getMyPolicy", async () => {
  const res = await call("get", "profile_manager/my_policy")();
  const my_policy: any = Object.values(res.data.result[0]).reduce((a: any, b: any) => a.concat(b), []);
  const policies = my_policy.reduce(
    (prev: { [apiName: string]: string[] }, current: { api: string; methods: string[]; resource?: string[] }) => {
      if (current.resource) {
        current.resource.forEach((r: string) => {
          const apiName = current.api + "/" + r;
          prev[apiName] = current.methods;
        });
      } else {
        prev[current.api] = current.methods;
      }
      return prev;
    },
    {}
  );
  return {
    policies,
  };
});

export const getCompanyChoices = createAsyncThunk(SLICE_NAME + "/getCompanyChoices", async () => {
  const res = await call("get", "authorizer/company_choices", {
    origin: API_ORIGIN_ASSESSMENT,
    doReload: false,
  })();
  const companyChoices = res.data.result
    .map((r: any) => {
      return {
        company_code: r.company_code as string,
        company_id: r.company_id as number,
        company_name: r.company_name as string,
        services: r.services as { [role: string]: number[] },
        vendor_company_code: r.vendor_company_code as string,
        vendor_company_id: r.vendor_company_id as number,
        vendor_company_name: r.vendor_company_name as string,
      } as CompanyChoice;
    })
    .sort((a: CompanyChoice, b: CompanyChoice) => a.company_name.localeCompare(b.company_name));
  return companyChoices;
});

export const sendPasswordResetMail = createAsyncThunk(
  SLICE_NAME + "/sendPasswordResetMail",
  async (option: { loginCode: string; companyCode: string; language: string }) => {
    /*
        - id_token の取得
      */
    const result = await new Promise<{
      finished: boolean;
    }>(async (resolve, reject) => {
      try {
        const res = await call("post", "account_manager/remind_password", {
          origin: API_ORIGIN_ASSESSMENT,
          doReload: false,
        })({
          login_code: option.loginCode,
          company_code: option.companyCode,
          language: option.language,
        });
        resolve({
          finished: res.data.result.finished === 1,
        });
      } catch (e) {
        reject("error");
      }
    });

    return result;
  }
);
export const postNewPassword = createAsyncThunk(
  SLICE_NAME + "/postNewPassword",
  async (option: { ot: string; password: string; language: string }) => {
    /*
        - id_token の取得
      */
    const result = await new Promise<{
      finished: boolean;
      reason: string;
      hint: number;
    }>(async (resolve, reject) => {
      try {
        const res = await call("post", "account_manager/reset_password", {
          origin: API_ORIGIN_ASSESSMENT,
          doReload: false,
        })({
          ot: option.ot,
          password: option.password,
          language: option.language,
        });
        resolve({
          finished: res.data.result.finished === 1,
          reason: "",
          hint: 0,
        });
      } catch (e: any) {
        resolve({
          finished: false,
          ...e.response.data.errors[0],
        });
      }
    });

    return result;
  }
);

export const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setInitialized: (state, action) => {
      state.initialized = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserByIdToken.pending, (state) => {
      state.processing = true;
    });
    builder.addCase(getUserByIdToken.fulfilled, (state, action) => {
      const companyChoices = [...state.user.companyChoices];
      state.user = action.payload.user as User;
      state.user.companyChoices = companyChoices;
      state.exp = action.payload.exp;
      state.roles = action.payload.roles;
      state.processing = false;
    });
    builder.addCase(getUserByIdToken.rejected, (state) => {
      state.processing = false;
    });
    builder.addCase(getMyPolicy.pending, (state) => {
      state.processing = true;
    });
    builder.addCase(getMyPolicy.fulfilled, (state, action) => {
      state.policies = action.payload.policies;
      state.processing = false;
    });
    builder.addCase(getMyPolicy.rejected, (state) => {
      state.processing = false;
    });
    builder.addCase(getCompanyChoices.fulfilled, (state, action) => {
      state.user.companyChoices = action.payload;
      state.processing = false;
    });
    builder.addCase(getCompanyChoices.rejected, (state) => {
      state.processing = false;
    });
    builder.addCase(postNewPassword.fulfilled, (state, action) => {
      state.newPasswordErrorMessage = action.payload.finished ? "" : action.payload.reason ?? "";
      state.newPasswordErrorHint = action.payload.finished ? 0 : action.payload.hint;
    });
  },
});
export const { setInitialized } = slice.actions;

export const selectUserState = (state: RootState) => {
  return state.user as UserState;
};

export const selectUserRootRoles = (state: RootState) => {
  const rootRoleList: string[] = [];
  state.user.roles.forEach((role: string) => {
    const root = role.split(".").filter((_) => _)[0];
    if (!rootRoleList.includes(root)) rootRoleList.push(root);
  });
  return rootRoleList;
};

export const selectHasDataLoaderPermissions = (state: RootState) => {
  return (state.user.policies.data_loader ?? []).some((_: string) => _ === "POST");
};
export const selectHasPostMasterPermissions = (state: RootState) => {
  return Object.keys(state.user.policies)
    .filter((key: string) => key.includes("master_data_manager"))
    ?.some((api) => state.user.policies[api]?.includes("POST"));
};
export const selectHasPostUserPermissions = (state: RootState) => {
  return Object.keys(state.user.policies)
    .filter((key: string) => key.includes("user_data_manager"))
    ?.some((api) => state.user.policies[api]?.includes("POST"));
};
export const selectHasGetUserPermissions = (state: RootState) => {
  return Object.keys(state.user.policies)
    .filter((key: string) => key.includes("user_data_manager"))
    ?.some((api) => state.user.policies[api]?.includes("GET"));
};
export const selectHasPostAccessPermissions = (state: RootState) => {
  return (state.user.policies.access_manager ?? [])?.some((_: string) => _ === "POST");
};
export const selectHasPostTemplatePermissions = (state: RootState) => {
  return (state.user.policies.template_manager ?? [])?.some((_: string) => _ === "POST");
};
export const selectHasPostAcountAdminPermissions = (state: RootState) => {
  return (state.user.policies.account_admin_manager ?? [])?.some((_: string) => _ === "POST");
};

export const selectHasOthersReportAccessPermissions = (state: RootState) => {
  // TODO 自分の分は取得できるが他者の分はできない、をどう表現するのがよいか
  return (state.user.policies.report_manager ?? [])?.some((_: string) => _ === "GET");
};
export const selectCurrentCompany = (state: RootState) => {
  return state.user.user.current_company as CurrentCompany;
};

const Module = {
  name: SLICE_NAME,
  reducer: slice.reducer,
};
export default Module;
