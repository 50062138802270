import { useState, useEffect, useMemo, Fragment } from "react";
import { useParams, useLocation } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Alert,
  Card,
  Modal,
  Accordion,
  ButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import { AccordionEventKey } from "react-bootstrap/AccordionContext";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAppSelector, useAppDispatch } from "../../app/store";
import { scroller } from "react-scroll";
import { getQuery, testResponse } from "../../app/util";
import { selectUserState, User } from "../../features/login/userSlice";
import {
  getUserData,
  stageProfileSubFields,
  selectProfileState,
  unselectProfile,
  commitUserData,
  deleteUserData,
  getMembers,
  createEmptyProfile,
  copyProfile,
  attachFile,
  downloadFile,
  deleteFile,
  validatePermissions,
  getUserDataById,
  getMyStaff,
  checkOtherAccountPermission,
} from "../../features/profile/profileSlice";
import { ProfileSubField } from "../../features/profile/profileFieldValues";
import {
  validateProfileField,
  ProfileFieldStatus,
  fieldCategoryGroups,
  profileSectorStaff,
  tablesOfCategory,
  USER_TABLE_PREFIX,
} from "../../features/profile/profileValues";
import OtherMemberSelector from "./OtherMemberSelector";
import SnapshotTimeSelector, { onChangeOption } from "../../features/profile/SnapshotTimeSelector";
import Sidebar from "../../component/Sidebar";
import { TableCol } from "../../component/Table";
import ProfileField from "../../features/profile/ProfileField";
import { getRegularColumns, getSectors, selectClientState, selectActiveSectors } from "../client/clientSlice";
import dayjs from "dayjs";
import "react-calendar/dist/Calendar.css";
import classNames from "classnames";
import ModalDialog from "../../component/ModalDialog";
import MyStaffSelector from "./MyStaffSelector";
import { isPermitted } from "../permission/permissionValues";

type DisplaySwitch = {
  label: string;
  key: string;
  isAvailable: (user: User, hasSubordinate: boolean, hasOtherAccountPermission: boolean) => boolean;
};

const DISPLAY_SWITCHES: DisplaySwitch[] = [
  {
    label: "自分",
    key: "self",
    isAvailable: (user, _) => {
      // ・ユーザロール
      // ・ゲストではない
      return user.role === "user" && user.current_company.code === user.main_company;
    },
  },
  {
    label: "部下",
    key: "subordinate",
    isAvailable: (user, hasSubordinate, hasOtherAccountPermission) => {
      // ・ユーザロール
      // ・ゲストではない
      // ・部下がいる
      // ・他アカウントに対する権限がある
      return (
        user.role === "user" &&
        user.current_company.code === user.main_company &&
        hasSubordinate &&
        hasOtherAccountPermission
      );
    },
  },
  {
    label: "検索",
    key: "search",
    isAvailable: (_, __, hasOtherAccountPermission) => {
      // ・他アカウントに対する権限がある
      return hasOtherAccountPermission;
    },
  },
];
function ProfileDetail() {
  const dispatch = useAppDispatch();
  const { user, policies } = useAppSelector(selectUserState);
  const {
    profileFields,
    profileFiles,
    permissionsByTargetAccount,
    members,
    accounts,
    myStaffs,
    hasOtherAccountPermission,
  } = useAppSelector(selectProfileState);
  const { sectorRegularColumns } = useAppSelector(selectClientState);
  const activeSectors = useAppSelector(selectActiveSectors);
  const { memberId } = useParams();
  const { search } = useLocation();
  const query = useMemo(() => {
    return getQuery(search);
  }, [search]);
  const defaultDate = useMemo(() => {
    if (query.base_date) {
      const d = dayjs(query.base_date);
      if (Object.keys(policies).length === 0) return null; // policiesが取得できない間はnullを返す
      if (isPermitted(policies, `user_data_manager/${query.field || "personal"}`, "PUT")) return d;
      if (d.isAfter(dayjs())) return dayjs();
      return d;
    } else if (query.id) {
      return null;
    } else {
      return dayjs();
    }
  }, [query, policies]);
  const urlUniqueKeysConditions = useMemo(() => {
    let _conditions = {};
    for (const q in query) {
      if (q && !["base_date", "field", "type", "id"].includes(q)) {
        _conditions = {
          ..._conditions,
          [q]: query[q],
        };
      }
    }
    return _conditions;
  }, [query]);

  const [state, $state] = useState({
    activeFieldType: (query.type as "detail" | "history") || "detail",
    activeCategory: query.field || "personal",
    activeKey: fieldCategoryGroups[0].id as AccordionEventKey,
    checkedAccountId: Number(memberId) as number | undefined,
    selectedPointDate: defaultDate,
    fieldStatus: {} as ProfileFieldStatus,
    mode: "previewing",
    activeModal: "",
    targetId: 0, // 既存の家族に対して履歴追加する際のID情報
    uniqueKeysConditions: urlUniqueKeysConditions as { [key: string]: any },
    selectedId: null as number | null,
    displayTab: null as "self" | "subordinate" | "search" | null,
  });
  const [errorModal, $errorModal] = useState("");
  const activeFieldCategorieGroups = useMemo(() => {
    if (!activeSectors || activeSectors.length === 0 || !state.checkedAccountId) return [];
    // sectorStatusがactiveのもののみを表示
    return fieldCategoryGroups.map((group) => {
      return {
        ...group,
        categories: group.categories.filter(
          (c) =>
            state.checkedAccountId &&
            activeSectors.some((s) => s.sector_id === `profile_u_${c.id}`) &&
            permissionsByTargetAccount[+state.checkedAccountId]?.[c.id]?.["is_view_permitted"]
        ),
      };
    });
  }, [fieldCategoryGroups, activeSectors, permissionsByTargetAccount]);

  const switches = useMemo(() => {
    const hasSubordinate = myStaffs.some((staff) => staff.id !== user.id);
    return DISPLAY_SWITCHES.filter((s) => s.isAvailable(user, hasSubordinate, hasOtherAccountPermission));
  }, [user, myStaffs, hasOtherAccountPermission]);

  useEffect(() => {
    const tables = tablesOfCategory[state.activeCategory].tables;
    tables.forEach((t) => {
      const sectorId = `${USER_TABLE_PREFIX}${t}`;
      !sectorRegularColumns[sectorId] && dispatch(getRegularColumns({ sectorId }));
    });
  }, [state.activeCategory]);

  const fieldLabelMap = useMemo(() => {
    // 選択されているタブのfieldのkeyとlabelをMapにしたもの
    return tablesOfCategory[state.activeCategory].tables.reduce((prev, current) => {
      const sectorId = `${USER_TABLE_PREFIX}${current}`;
      const label = activeSectors.find((s) => s.sector_id === sectorId)?.logical_name ?? "";
      return { ...prev, [current]: label };
    }, {} as { [key: string]: string });
  }, [activeSectors, state.activeCategory]);

  const initActiveFieldType = useMemo(() => {
    if (state.activeCategory) {
      return tablesOfCategory[state.activeCategory].isBaseDateRequired === false ? "history" : "detail";
    }
  }, [state.activeCategory]);

  const isEditPermitted = useMemo(() => {
    if (!state.checkedAccountId) return false;
    return permissionsByTargetAccount[+state.checkedAccountId]?.[state.activeCategory]?.["is_edit_permitted"];
  }, [permissionsByTargetAccount, state.checkedAccountId, state.activeCategory]);
  useEffect(() => {
    // 編集権限がない、かつ基準日が未来の場合は基準日を本日へ変更する
    if (!isEditPermitted && state.selectedPointDate && state.selectedPointDate.isAfter(dayjs()))
      $state({ ...state, selectedPointDate: dayjs() });
  }, [isEditPermitted]);
  const onSelectedAccountChange = (id?: number) => {
    if (id !== state.checkedAccountId) {
      const activeFieldType =
        tablesOfCategory[state.activeCategory].isBaseDateRequired === false ? "history" : "detail";
      $state({ ...state, checkedAccountId: id, activeFieldType, uniqueKeysConditions: {} });
    }
  };

  const updatePreview = async (options?: {
    activeFieldType?: "detail" | "history";
    activeCategory?: string;
    accountId?: number;
    baseDate?: dayjs.Dayjs;
    uniqueKeysConditions: { [key: string]: any };
  }) => {
    // selectedPointDate=null （= id がクエリに含まれるため、データ取得中）は、何もしない
    if (state.selectedPointDate === null) return;
    const activeCategory = options?.activeCategory ?? state.activeCategory;
    const activeFieldType = options?.activeFieldType ?? state.activeFieldType;
    const accountId = options?.accountId ?? state.checkedAccountId;
    const baseDate =
      options?.baseDate ?? (activeFieldType === "detail" && activeCategory.indexOf("_summary") === -1)
        ? state.selectedPointDate.format("YYYY-MM-DD")
        : undefined;
    const uniqueKeysConditions = options?.uniqueKeysConditions ?? state.uniqueKeysConditions;
    if (!accountId) {
      window.history.replaceState({}, "", "/_/profile/");
      return;
    }
    if (permissionsByTargetAccount[+accountId] === undefined) return;
    if (!permissionsByTargetAccount[+accountId]?.[activeCategory]?.["is_view_permitted"]) {
      // 閲覧権限がない場合、閲覧権限のあるテーブルに切り替え
      const permittedCategories = Object.keys(permissionsByTargetAccount[+accountId]).filter(
        (c) => permissionsByTargetAccount[+accountId][c]["is_view_permitted"]
      );
      const activeCategory =
        permittedCategories.length > 0 && !permittedCategories.includes("personal")
          ? permittedCategories[0]
          : "personal";
      const activeTab = fieldCategoryGroups.filter((group) =>
        group.categories.some((category) => category.id === activeCategory)
      );
      $state({
        ...state,
        activeKey: activeTab[0].id,
        activeCategory: activeCategory,
        activeFieldType: tablesOfCategory[activeCategory].isBaseDateRequired === false ? "history" : "detail",
      });
      return;
    }
    await dispatch(
      getUserData({
        activeFieldType: activeFieldType,
        categoryId: activeCategory,
        baseDate: baseDate,
        accountId,
        sortBy: sectorRegularColumns[`${USER_TABLE_PREFIX}${state.activeCategory}`]
          .filter((col) => col.isKey)
          .map((_) => _.id),
        uniqueKeysConditions,
      })
    );
    // url更新
    let uniqueKeyQuery = "";
    for (const c in uniqueKeysConditions) {
      if (c) {
        uniqueKeyQuery += `&${c}=${uniqueKeysConditions[c]}`;
      }
    }
    const updatedUrl =
      activeFieldType === "history"
        ? `/_/profile/${state.checkedAccountId}/?field=${activeCategory}&type=history`
        : `/_/profile/${state.checkedAccountId}/?field=${activeCategory}&base_date=${baseDate}&type=detail${uniqueKeyQuery}`;
    window.history.replaceState({}, "", updatedUrl);
    if (state.mode !== "previewing") $state({ ...state, mode: "previewing" });
    scrollToTable();
  };

  useEffect(() => {
    if (user.id === 0) return;
    dispatch(getSectors());
    dispatch(checkOtherAccountPermission(user.id));
    if (user.role === "user") dispatch(getMyStaff());
    return () => {
      dispatch(unselectProfile());
    };
  }, [user]);

  useEffect(() => {
    if (query.id) return; // id指定の場合はこの処理は行わない
    if (switches.length === 0) return; // 表示できるタブがない場合は何もしない
    const accountId = Number(memberId);
    const displayTab = (() => {
      if (accountId) {
        if (accountId === user.id && switches.some(({ key }) => key === "self")) return "self";
        return "search";
      }
      const firstTab = (switches[0]?.key as "self" | "search") || null;
      return firstTab;
    })();
    const checkedAccountId = (() => {
      if (accountId) return accountId;
      if (displayTab === "self") return user.id;
      if (displayTab !== null) return state.checkedAccountId;
      return undefined;
    })();
    $state({
      ...state,
      displayTab,
      activeCategory: query.field || "personal",
      activeFieldType: (query.type as "detail" | "history") || "detail",
      checkedAccountId,
      selectedPointDate: defaultDate,
      fieldStatus: {} as ProfileFieldStatus,
      mode: "previewing",
    });
  }, [user, query, memberId, switches]);

  useEffect(() => {
    // idがクエリに含まれる場合、データ取得
    if (!query.id || !state.checkedAccountId) return;
    dispatch(getUserDataById({ id: +query.id, table: state.activeCategory, accountId: state.checkedAccountId })).then(
      (result) => {
        $state({
          ...state,
          activeCategory: query.field || "personal",
          activeFieldType: "detail",
          checkedAccountId: Number(memberId),
          selectedPointDate: result.payload ? dayjs(result.payload.valid_from) : dayjs(),
          fieldStatus: {} as ProfileFieldStatus,
          mode: "previewing",
          activeModal: result.payload ? "" : "record_not_exist",
          selectedId: +query.id,
        });
      }
    );
  }, [query.id]);

  useEffect(() => {
    if (state.checkedAccountId) {
      dispatch(getMembers({ accountId: state.checkedAccountId }));
    }
  }, [state.checkedAccountId]);

  const currentMember = useMemo(() => {
    if (members.length === 0) return;
    return members.filter((_) => _.id === state.checkedAccountId);
  }, [state.checkedAccountId, members]);

  const scrollToTable = () => {
    // エレメントが描画しきらないパターンがあるので、少し遅延させる
    window.setTimeout(() => {
      scroller.scrollTo(state.selectedId ? `record_${state.selectedId}` : "view", {
        duration: 200,
        delay: 300,
        smooth: true,
        offset: -50,
      });
      if (state.selectedId) $state({ ...state, selectedId: null });
    }, 10);
  };
  useEffect(() => {
    dispatch(unselectProfile());
    if (tablesOfCategory[state.activeCategory].tables.every((t) => sectorRegularColumns[`${USER_TABLE_PREFIX}${t}`])) {
      updatePreview();
    }
  }, [
    state.checkedAccountId,
    state.activeCategory,
    state.selectedPointDate,
    state.activeFieldType,
    state.uniqueKeysConditions,
    sectorRegularColumns,
    permissionsByTargetAccount,
  ]);

  useEffect(() => {
    if (state.checkedAccountId && state.checkedAccountId > 0) {
      // 権限チェック
      dispatch(
        validatePermissions({
          account_id: +state.checkedAccountId,
        })
      );
    }
  }, [state.checkedAccountId]);

  const onChangeToHistory = () => {
    // 詳細から履歴表示に切り替える
    $state({ ...state, activeFieldType: "history", uniqueKeysConditions: {} });
  };

  return (
    <div className="Layout">
      <div className="Layout__side">
        <Sidebar current={"profile"} />
      </div>
      <div className="Layout__main">
        <h1 className="Headline--page">従業員情報（個別）</h1>
        <main className="mt-3 py-4 px-md-2 bg-white">
          <Container>
            {(switches.length > 1 || switches.some(({ key }) => key !== "self")) && (
              <Row>
                <Col md="2">
                  <div className="--bold pt-2">対象者</div>
                </Col>
                <Col md="10">
                  {switches.length > 1 && (
                    <Row>
                      <Col>
                        <ButtonGroup>
                          {switches.map((type) => (
                            <ToggleButton
                              key={type.key}
                              id={`radio-${type.key}`}
                              type="radio"
                              variant={"outline-primary"}
                              name="radio"
                              value={type.key}
                              checked={state.displayTab === type.key}
                              onChange={(e) => {
                                const displayTab = e.currentTarget.value as "self" | "subordinate" | "search";
                                const checkedAccountId = displayTab === "self" ? user.id : undefined;
                                $state({ ...state, displayTab, checkedAccountId });
                              }}
                            >
                              {type.label}
                            </ToggleButton>
                          ))}
                        </ButtonGroup>
                      </Col>
                    </Row>
                  )}
                  {state.displayTab === "subordinate" ? (
                    <Row>
                      <Col>
                        <MyStaffSelector
                          onSelectedAccountChange={onSelectedAccountChange}
                          selectedAccountId={state.checkedAccountId}
                        />
                      </Col>
                    </Row>
                  ) : state.displayTab === "search" ? (
                    <Row>
                      <Col>
                        <OtherMemberSelector
                          onSelectedAccountChange={onSelectedAccountChange}
                          selectedAccountId={state.checkedAccountId}
                        />
                      </Col>
                    </Row>
                  ) : null}
                </Col>
              </Row>
            )}

            <Row className="mt-4 flex-column-reverse flex-sm-row">
              <Col sm="9" className="mt-4 mt-sm-0">
                {activeFieldCategorieGroups.length > 0 ? (
                  <Accordion
                    defaultActiveKey={activeFieldCategorieGroups[0].id}
                    activeKey={state.activeKey}
                    onSelect={(eventKey: AccordionEventKey) => {
                      $state({ ...state, activeKey: Array.isArray(eventKey) ? eventKey[0] : eventKey ?? "" });
                    }}
                  >
                    {activeFieldCategorieGroups
                      .filter((group) => group.categories && group.categories.length > 0)
                      .map((group) => {
                        return (
                          <Accordion.Item eventKey={group.id} key={group.id}>
                            <Accordion.Header>
                              <div
                                className={classNames({
                                  "--bold": group.categories.some((c) => state.activeCategory === c.id),
                                })}
                              >
                                {group.label}
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="--flex --wrap">
                                {group.categories.map(({ id, label }) => (
                                  <Button
                                    onClick={() => {
                                      const activeFieldType =
                                        tablesOfCategory[id].isBaseDateRequired === false ? "history" : "detail";
                                      $state({
                                        ...state,
                                        activeCategory: id,
                                        activeFieldType,
                                        uniqueKeysConditions: {},
                                        fieldStatus: {},
                                      });
                                    }}
                                    className="mx-1 my-1"
                                    variant={state.activeCategory === id ? "primary" : "light"}
                                    key={`button_${id}`}
                                    disabled={state.activeCategory === id}
                                  >
                                    {label["ja"]}
                                  </Button>
                                ))}
                              </div>
                              {/* <Tabs
                              className="mb-3"
                              onSelect={(key) => {
                                if (key) {
                                  const activeFieldType =
                                    tablesOfCategory[key].isBaseDateRequired === false ? "history" : "detail";
                                  $state({
                                    ...state,
                                    activeCategory: key,
                                    activeFieldType,
                                    uniqueKeysConditions: {},
                                    fieldStatus: {},
                                  });
                                }
                              }}
                              activeKey={state.activeCategory}
                            >
                              {group.categories.map(({ id, label }) => (
                                <Tab eventKey={id} title={label["ja"]} key={`tab_${id}`}></Tab>
                              ))}
                            </Tabs> */}
                            </Accordion.Body>
                          </Accordion.Item>
                        );
                      })}
                  </Accordion>
                ) : null}
                {state.activeFieldType === "detail" ? (
                  <Row className="mt-2">
                    <Col md="2">
                      <div className="--bold pt-md-3">基準日</div>
                    </Col>
                    <Col md="10">
                      <SnapshotTimeSelector
                        selectedPointDate={state.selectedPointDate}
                        onChange={({ selectedPointDate }: onChangeOption) => {
                          if (!selectedPointDate) return;
                          $state({ ...state, selectedPointDate });
                        }}
                        useFarthestPastDaySelector={false}
                        useFarthestDaySelector={isEditPermitted}
                        maxDate={isEditPermitted ? undefined : dayjs().toDate()}
                      />
                    </Col>
                  </Row>
                ) : (
                  <div></div>
                )}
              </Col>
              <Col sm="3">
                {currentMember &&
                  currentMember.length > 0 &&
                  (() => {
                    const mainAssignment = currentMember.find((_) => !_.isConcurrent);
                    const concurrentAssignments = currentMember.filter((_) => _.isConcurrent);
                    return (
                      <div className="pt-3 pt-sm-0">
                        <div className="Profile-card">
                          {currentMember[0].image && (
                            <figure className="Profile-card__image mb-2">
                              <img src={currentMember[0].image} alt={currentMember[0].name} />
                            </figure>
                          )}
                          <div className="Profile-card__data">
                            <div className="Profile-card__label">名前</div>
                            <div className="Profile-card__value">{currentMember[0].name}</div>
                          </div>
                          <div className="Profile-card__data">
                            <div className="Profile-card__label">部署</div>
                            <div className="Profile-card__value">
                              {mainAssignment?.fullSectionName} ({mainAssignment?.sectionCode})
                            </div>
                          </div>
                          <div className="Profile-card__data">
                            <div className="Profile-card__label">役職</div>
                            <div className="Profile-card__value">
                              {mainAssignment?.positionName} ({mainAssignment?.positionCode})
                            </div>
                          </div>
                          {concurrentAssignments.map((_, index) => {
                            return (
                              <div className="Profile-card__data" key={_.sectionCode}>
                                {index === 0 ? (
                                  <div className="Profile-card__label">兼務</div>
                                ) : (
                                  <div className="Profile-card__blank-label"></div>
                                )}
                                <div className="Profile-card__value">
                                  {_.fullSectionName} ({_.positionName})
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })()}
              </Col>
            </Row>
            <Row className="mt-4" id="view">
              <Col>
                {state.checkedAccountId ? (
                  <Row className="my-4">
                    <Col>
                      {state.activeFieldType === "detail" &&
                        permissionsByTargetAccount[+state.checkedAccountId]?.[state.activeCategory]?.[
                          "is_view_permitted"
                        ] && (
                          <Button
                            variant="outline-primary"
                            className="mx-2"
                            disabled={state.checkedAccountId === 0 || state.mode !== "previewing"}
                            onClick={() => {
                              onChangeToHistory();
                            }}
                          >
                            履歴表示に切り替える
                          </Button>
                        )}
                      {permissionsByTargetAccount[+state.checkedAccountId]?.[state.activeCategory]?.[
                        "is_edit_permitted"
                      ] && (
                        <Button
                          className="mx-2"
                          disabled={state.checkedAccountId === 0 || state.mode !== "previewing"}
                          onClick={() => {
                            // 家族で履歴が表示されている場合
                            if (
                              profileFields.length > 0 &&
                              profileFields[0].subFields.some(
                                (s) => s.type === "tagHandler" && s.isRelational && s.tagGroupsToUse.length > 0
                              )
                            ) {
                              $state({ ...state, activeModal: "before_creating" });
                            } else {
                              // 家族以外で履歴が表示されていて、かつ詳細画面の場合（家族では履歴がない場合でもprofileFieldsの要素はあるため、アカウントIDの条件を追加して履歴がないことを判定する）
                              if (
                                profileFields.length > 0 &&
                                profileFields[0].account.id !== -1 &&
                                state.activeFieldType === "detail"
                              ) {
                                dispatch(
                                  copyProfile({
                                    category: state.activeCategory,
                                    accountId: state.checkedAccountId ?? 0,
                                    subFields: profileFields[0]?.subFields,
                                  })
                                );
                              }
                              // 履歴が表示されていない場合や履歴一覧表示画面の場合
                              else {
                                dispatch(
                                  createEmptyProfile({
                                    category: state.activeCategory,
                                    accountId: state.checkedAccountId ?? 0,
                                    columns: sectorRegularColumns[`${USER_TABLE_PREFIX}${state.activeCategory}`],
                                  })
                                );
                              }
                              const fieldStatus = {
                                [state.activeCategory]: {
                                  validated: false,
                                  errorMessage: "入力してください",
                                },
                              } as ProfileFieldStatus;
                              $state({ ...state, fieldStatus, mode: "creating" });
                            }
                          }}
                          variant="outline-primary"
                        >
                          履歴追加
                        </Button>
                      )}
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
                {profileFields.length > 0 &&
                (!profileFields[0].subFields.some((s) => s.type === "tagHandler") ||
                  profileFields[0].subFields.some((s) => s.type === "tagHandler" && !s.isRelational) ||
                  profileFields[0].subFields.some(
                    (s) => s.type === "tagHandler" && s.isRelational && s.tagGroupsToUse.length > 0
                  )) ? (
                  <Row className="g-1">
                    {profileFields.map((profileField, profileFieldIndex) => {
                      const handler = (fieldName: string, next: ProfileSubField[], mode?: string) => {
                        const { validated, subFields } = validateProfileField(profileField, next);
                        // 入力を開始したかの属性とエラーメッセージを更新
                        const fieldStatus = { ...state.fieldStatus };
                        fieldStatus[fieldName] = fieldStatus[fieldName] || {
                          validated: true,
                          errorMessage: "",
                        };
                        fieldStatus[fieldName].validated = validated;
                        fieldStatus[fieldName].errorMessage = validated ? "" : "入力内容を確認してください";

                        subFields.forEach((sf) => {
                          const path = `${fieldName}/${sf.id}`;
                          fieldStatus[path] = fieldStatus[path] || {
                            validated: true,
                            errorMessage: "",
                          };
                          fieldStatus[path].validated = !sf.errorMessage;
                          fieldStatus[path].errorMessage = sf.errorMessage ?? "";
                        });
                        $state({
                          ...state,
                          fieldStatus,
                          mode: mode || state.mode,
                        });
                        dispatch(
                          stageProfileSubFields({
                            fieldName,
                            validated,
                            subFields,
                          })
                        );
                      };
                      // 履歴の行選択
                      const onSelectRow = (id: string | number, data: (string | string[])[], cols: TableCol[]) => {
                        // 開始日を基準日とし、その他のユニークキーによる条件を生成
                        let selectedRowData = {} as { [key: string]: any };
                        cols.forEach((col, i) => {
                          if (col.fieldName) {
                            selectedRowData = { ...selectedRowData, [col.fieldName]: data[i] };
                          }
                        });

                        const regularColumns = sectorRegularColumns[`${USER_TABLE_PREFIX}${profileField.table}`];
                        const uniqueKeys = regularColumns.filter((col) => col.isKey).map((_) => _.id);
                        let uniqueKeysConditions = {} as { [key: string]: any };
                        let baseDate = "";
                        for (const col in selectedRowData) {
                          if (uniqueKeys.includes(col)) {
                            if (col === "valid_from") {
                              baseDate = selectedRowData[col];
                            } else {
                              uniqueKeysConditions[col] = selectedRowData[col];
                            }
                          }
                        }
                        $state({
                          ...state,
                          activeFieldType: "detail",
                          selectedPointDate: dayjs(baseDate),
                          uniqueKeysConditions,
                        });
                      };

                      return (
                        <Col
                          md={12}
                          key={`profileField${profileFieldIndex}`}
                          className={classNames({
                            "--hidden": profileField.category !== state.activeCategory,
                          })}
                        >
                          <ProfileField
                            fieldName={profileField.fieldName}
                            table={profileField.table}
                            labelMap={{ ja: { ...profileField.labelMap.ja, ...fieldLabelMap } }}
                            accountId={state.checkedAccountId}
                            subFields={profileField.subFields}
                            isEditing={state.mode !== "previewing"}
                            fieldStatus={state.fieldStatus}
                            onChange={(next) => handler(profileField.fieldName, next)}
                            onEditOrCancel={async (next, isEditng?: boolean) => {
                              if (!isEditng) {
                                // 履歴追加キャンセル時
                                if (initActiveFieldType === "history") {
                                  onChangeToHistory(); // state更新によるupdatePreview実行に任せる
                                } else {
                                  await updatePreview();
                                }
                              } else {
                                // 履歴追加 または 修正ボタン押下（現在のstate.modeが"previewing"）時、modeを"editing"に変更
                                const mode = state.mode === "previewing" ? "editing" : state.mode;
                                handler(profileField.fieldName, next, mode);
                              }
                            }}
                            onDelete={async (next, recordId) => {
                              await dispatch(deleteUserData({ table: profileField.table, data: { id: recordId } }))
                                .unwrap()
                                // 削除失敗：エラーメッセージを表示
                                .catch((e) => $errorModal(e.message));
                              if (initActiveFieldType === "history") {
                                onChangeToHistory(); // state更新によるupdatePreview実行に任せる
                              } else {
                                // 削除した履歴の基準日のままだと他のレコードが表示されない場合があるため、削除後は基準日を本日に変更する
                                $state({ ...state, selectedPointDate: dayjs(new Date()) });
                              }
                            }}
                            onSelectRow={onSelectRow}
                            onChangeToHistory={onChangeToHistory}
                            onCommit={async (next, validDateMap) => {
                              const { validated } = validateProfileField(profileField, next);
                              if (!validated) return;
                              const fieldsToUpdate = profileSectorStaff[profileField.fieldName].toDiffList(
                                next,
                                validDateMap
                              );
                              $state({ ...state, mode: "previewing" });
                              if (fieldsToUpdate.length === 0) {
                                if (initActiveFieldType === "history") onChangeToHistory();
                                return;
                              }
                              await dispatch(
                                commitUserData({
                                  activeFieldType: state.activeFieldType,
                                  categoryId: state.activeCategory,
                                  table: profileField.table,
                                  data: fieldsToUpdate,
                                  accountLoginCode: accounts?.[0].loginCode,
                                })
                              );
                              const uniqueKeys = sectorRegularColumns[`${USER_TABLE_PREFIX}${profileField.table}`]
                                .filter((_) => _.isKey === true)
                                .map((_) => _.id);
                              let uniqueKeysConditions = {} as { [key: string]: any };
                              next.forEach((_) => {
                                if (uniqueKeys.includes(_.id)) {
                                  uniqueKeysConditions[_.id] = _.value;
                                }
                              });
                              if (initActiveFieldType === "history") {
                                onChangeToHistory(); // state更新によるupdatePreview実行に任せる
                              } else {
                                // 履歴追加の場合および開始日が修正された場合は、基準日をその開始日に切り替える
                                const newValidFrom = Object.values(validDateMap).map((value) => value.valid_from)[0];
                                if (newValidFrom) $state({ ...state, selectedPointDate: dayjs(newValidFrom) });
                                else updatePreview();
                              }
                            }}
                            files={profileFiles}
                            onFileAdd={async (subField, decodedFileData) => {
                              await dispatch(
                                attachFile({
                                  activeFieldType: state.activeFieldType,
                                  categoryId: state.activeCategory,
                                  table: profileField.table,
                                  subField: subField,
                                  decodedFileData: decodedFileData,
                                  accountLoginCode: accounts?.[0].loginCode,
                                })
                              );
                            }}
                            onFileDownload={async (fileId, key) => {
                              const result = testResponse(await dispatch(downloadFile({ fileId, key })));
                              if (!result) $state({ ...state, activeModal: "file_not_exist" });
                            }}
                            onFileDelete={async (fileId, key) => {
                              await dispatch(
                                deleteFile({
                                  fileId: fileId,
                                  key: key,
                                })
                              );
                            }}
                          />
                        </Col>
                      );
                    })}
                  </Row>
                ) : (
                  <Alert variant={"info"}>該当するレコードはありません。</Alert>
                )}
              </Col>
            </Row>
          </Container>
        </main>
      </div>
      <Modal
        show={state.activeModal === "before_creating"}
        onHide={() => {
          window.history.back();
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          {profileFields[0]?.subFields.some((s) => !s.isRelational && s.record.id > 0) && (
            <Fragment>
              履歴追加する対象を選択してください。
              <Card className="my-2">
                <Card.Body>
                  {profileFields[0]?.subFields
                    .filter((s) => !s.isRelational && /^dependent_name_\d$/.test(s.id) && s.record.id > 0)
                    .map((s, i) => (
                      <Form.Check
                        type="radio"
                        label={s.value}
                        key={`${s.record.id}`}
                        id={`dependent_${s.record.id}`}
                        checked={state.targetId === s.record.id}
                        value={s.record.id}
                        onChange={(e) => {
                          $state({
                            ...state,
                            targetId: +e.target.value,
                          });
                        }}
                      />
                    ))}
                </Card.Body>
              </Card>
            </Fragment>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              $state({ ...state, activeModal: "" });
            }}
            variant="outline-secondary"
          >
            キャンセル
          </Button>
          <Button
            onClick={() => {
              dispatch(
                createEmptyProfile({
                  category: state.activeCategory,
                  accountId: state.checkedAccountId ?? 0,
                  columns: sectorRegularColumns[`${USER_TABLE_PREFIX}${state.activeCategory}`],
                })
              );
              const fieldStatus = {
                [state.activeCategory]: {
                  validated: false,
                  errorMessage: "入力してください",
                },
              } as ProfileFieldStatus;
              $state({ ...state, fieldStatus, mode: "creating", activeModal: "" });
            }}
            variant="primary"
          >
            新しい {fieldLabelMap[state.activeCategory]} を追加する
          </Button>
          <Button
            onClick={() => {
              dispatch(
                copyProfile({
                  category: state.activeCategory,
                  accountId: state.checkedAccountId ?? 0,
                  subFields: profileFields[0]?.subFields.filter(
                    (s) => s.type === "tagHandler" || state.targetId === s.record.id
                  ),
                })
              );
              const fieldStatus = {
                [state.activeCategory]: {
                  validated: false,
                  errorMessage: "入力してください",
                },
              } as ProfileFieldStatus;
              $state({ ...state, fieldStatus, mode: "creating", activeModal: "" });
            }}
            variant="primary"
            disabled={state.targetId === 0}
          >
            選択した {fieldLabelMap[state.activeCategory]} に履歴追加する
          </Button>
        </Modal.Footer>
      </Modal>
      <ModalDialog
        show={state.activeModal === "file_not_exist"}
        onConfirm={() => $state({ ...state, activeModal: "" })}
        message="ファイルダウンロードできませんでした。ファイルが削除された可能性があります。"
        type="alert"
      />
      <ModalDialog
        show={state.activeModal === "record_not_exist"}
        onConfirm={() => $state({ ...state, activeModal: "" })}
        message="指定されたレコードが存在しないか、権限がありません。"
        type="alert"
      />
      <ModalDialog show={errorModal !== ""} onConfirm={() => $errorModal("")} message={errorModal} type="alert" />
    </div>
  );
}

export default ProfileDetail;
